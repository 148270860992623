.order-items {
    width: 700px;
    border: 1px solid black;
    padding: 20px;
    margin-bottom: 20px;
    display: flex;
    border-radius: 10px;
    align-items: center;
}

.order-items img {
    width: 170px;
    margin-right: 30px;
}

.order-details-container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.order-details h2 {
    font-weight: 600;
}

.order-details p {
    margin: 5px;
}

.delete-btn {
    width: 60%;
    margin-right: 40px;
    border-radius: 50%;
    padding: 10px;
    border: 0px solid black;
    background-color: rgb(255, 180, 180);
    cursor: pointer;
}

.delete-icon {
    font-size: 2rem;
    color: rgba(240, 24, 24, 0.77);
}

.delete-btn:hover {
    border: 1px solid rgb(176, 29, 29);
}