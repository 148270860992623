.header{
    width: 100%;
    background-color: #1C2B35;
    display: flex;
    justify-content: center;

}
.navbar{ 
    display: flex;
    align-items: center;
    justify-content:space-between;
    padding: 13px;
    width: 80%;
}

.navbar img{
    width: 140px;
}

.navItem a{
    font-size: 17px;
    color: white;
    margin-right: 20px;
    text-decoration: none;
}

.navItem a:hover{
  color: #FF9900;
}