.product-div {
     width: 300px;
    height: 508px;
    align-items: center;
    border: 1px solid #95A0A7;
    border-radius: 8px;
    position: relative;
}

.product-div img{
    width: 286px;
    display: block;
    margin: 7px;
}

.product-info{
    margin: 0 14px;
    margin-top: 8px;
}

.product-info p{
    margin-bottom: 0px;
    margin-top: 7px;
}

.product-name{
    font-weight: 600;
    line-height: 25px;
    margin-top: 7px;
}

.product-price{
    font-weight: 500;
}

.seller-rating{
    margin-top: 30px;
}

.btn-cart{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #FFE0B3;
    border: 0px solid #FFE0B3;
    border-radius: 0px 0px 8px 8px;
    position:absolute;
    bottom: 0;
}

.btn-cart p{
    margin-right: 4px;
}

.btn-cart:hover{
    background: #ffb84d;
}