.form-container {
    width: 500px;
    height: 600px;
    margin: 40px auto;
    border: 1px solid #95A0A7;
    border-radius: 8px;
    display: flex;
    justify-content: center;
}

.form-title {
    text-align: center;
    font-size: 35px;
    font-weight: 700;
    color: rgb(254, 165, 0);
}

.input-group {
    margin-bottom: 20px;
}

.input-group label {
    display: block;
    font-size: 17px;
    margin-left: 5px;
}


.input-group input {
    width: 415px;
    height: 55px;
    display: inline-block;
    border: 1px solid #ccc;
    box-sizing: border-box;
    font-size: 21px;
    padding: 8px;
}

.form-submit {
    background-color: rgba(255, 192, 76, 0.923);
    font-size: 24px;
}

.form-submit:hover {
    background-color: #ffa621;
}

.create-new-account {
    text-align: center;
}

.create-new-account a {
    text-decoration: none;
    color: #FF9900;
}

.or-hr {
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
    align-items: center;
}

.or-hr hr {
    width: 50%;
    margin-left: 30px;
    margin-right: 30px;
}

.button {
    width: 415px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;

}

.google-img {
    width: 32px;
}

.contiune-google {
    font-size: 17px;
    padding-left: 5px;
}


.loading-msg {
    color: #FF9900;
    text-align: center;
    font-weight: 500;
}