.signup-container {
    width: 500px;
    height: 700px;
    margin: 40px auto;
    border: 1px solid #95A0A7;
    border-radius: 8px;
    display: flex;
    justify-content: center;
}

.error-msg {
    text-align: center;
    color: rgb(255, 56, 26);
    font-weight: 500;
    margin: 0;
}