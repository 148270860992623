.shop-container {
    /* display: grid;
    grid-template-columns: 4fr 1fr; */
    display: flex;
}

.product-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 45px;
    margin-top: 108px;
    margin-left: 104px;
    margin-right: 92px;
}

.cart-container {
    background-color: rgba(255, 153, 0, 0.3);
}

.cart-container h2 {
    display: flex;
    justify-content: center;
}

.cart-container hr {
    background-color: black;
    font-weight: 700;
}

.cart-container-elements {
    margin-left: 15px;
}

.review-btn {
    width: 250px;
    height: 40px;
    font-size: 1.2rem;
    font-weight: 700;
    background-color: rgba(255, 181, 44, 0.923);
    color: rgb(0, 0, 0);
    border: 0px solid black;
    border-radius: 8px;
    margin-bottom: 50px;
}

.pagination button {
    margin-right: 10px;
    background-color: rgb(229, 229, 229);
    border: 1px solid rgb(0, 0, 0);
    width: 30px;
    height: 30px;
    margin-bottom: 50px;
}


.pagination .selected {
    background-color: orange;
    color: white;
}

@media screen and (max-width: 992px) {

    .shop-container {
        grid-template-columns: 3fr 1fr;
    }

    .product-container {
        grid-template-columns: repeat(1, 1fr);
    }

}

@media screen and (max-width: 576px) {

    .shop-container {
        grid-template-columns: 3fr 1fr;
    }

    .product-container {
        grid-template-columns: repeat(1, 1fr);
    }

}